import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import css from "../css/Landing.module.css";

const WAbout = () => {
  const { photographer } = useSelector((state) => state?.folderImages) || {};

  return (
    <Container id="about" fluid className={css.about}>
      <Row>
        <Col>
          <div>
            <h2>About Us</h2>
            <p>
              {photographer?.aboutUs?.split("\n").map((para, i) => (
                <React.Fragment key={i}>
                  {para}
                  <br />
                </React.Fragment>
              ))}
            </p>
            <div className={css.socials}>
              {photographer?.insta_link_d && (
                <a
                  href={`${photographer?.insta_link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/instagram_icon.png" />
                </a>
              )}
              {photographer?.whatsApp_link_d && (
                <a
                  href={`https://wa.me/${photographer?.whatsApp_link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/whatsapp.svg" />
                </a>
              )}
              {photographer?.vimeo_link_d && (
                <a
                  href={`${photographer?.vimeo_link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/v.svg" />
                </a>
              )}
              {photographer?.fb_link_d && (
                <a
                  href={`${photographer?.fb_link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/facebook.svg" />
                </a>
              )}
              {photographer?.youtube_link_d && (
                <a
                  href={`${photographer?.youtube_link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/youtube.svg" />
                </a>
              )}
              {photographer?.business_phone_d && (
                <a
                  href={`tel:${photographer?.business_phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/call.svg" />
                </a>
              )}
              {photographer?.website_d && (
                <a
                  href={`${photographer?.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/website.svg" />
                </a>
              )}
              {photographer?.business_email_d && (
                <a
                  href={`mailto:${photographer?.business_email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="media-icon text-center"
                >
                  <img src="../assets/icons/email.svg" />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WAbout;
